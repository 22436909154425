









































































import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsWizardCustomizeFundViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-customize-fund-view-model';

@Component({ name: 'FlagshipGoalsWizardCustomizeFund' })
export default class FlagshipGoalsWizardCustomizeFund extends Vue {
  flagship_wizard_customize_fund_model =
    Vue.observable(new FlagshipGoalsWizardCustomizeFundViewModel(this));

  created() {
    this.flagship_wizard_customize_fund_model.initialize();
  }
}
